import React from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../../components/Layout'
import BlogGames1920 from '../../components/BlogGames1920'
export default class RoomsIndexPage extends React.Component {
  render() {
    return (
      <Layout>

        <Helmet titleTemplate="%s | Room">
            <title>2019-2020 Season | Northcoast Game of the Week</title>
            <meta
                name="description"
                content="2019-2020 Season | Northcoast Game of the Week"
            />
        </Helmet>
        <h1>2019-2020 Season</h1>
        <BlogGames1920 />


      </Layout>
    )
  }
}
